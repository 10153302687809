.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__about-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem;

  .app__about-photo {
    flex: 0 0 auto;
    width: 270px;
    height: 327px;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @media screen and (min-width: 2000px) {
      width: 600px;
      height: auto;
      margin: 4rem;
    }
  }

  .app__about-bio {
    margin: 2rem 1rem;
    max-width: 600px;

    @media screen and (min-width: 2000px) {
      max-width: 1200px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
