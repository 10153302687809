.popup {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.4s,
    opacity 0.4s ease-in-out;
  z-index: 1020;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__figure {
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__image {
  max-width: 75vw;
  max-height: 75vh;
  object-fit: contain;
}

.popup__figcaption {
  margin: 10px 0 0 0;
  align-self: flex-start;
  color: #fff;
}

.popup__close {
  color: #fff;
  position: absolute;
  top: -40px;
  right: -40px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.popup__close:hover {
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .popup__close {
    width: 20px;
    height: 20px;
    top: -36px;
    right: 0;
  }
}
