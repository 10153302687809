.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__works-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .app__works-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }

  @media screen and (min-width: 2000px) {
    padding-bottom: 2rem;
  }
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  column-gap: 1rem;
  row-gap: 2rem;
  max-width: 320px;

  .app__work-item {
    flex-direction: column;
    flex: 0 0 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 270px;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 768px) {
      flex: 1 0 calc(50% - 1rem); // на экранах от 768px два элемента в строке
    }

    @media screen and (min-width: 1024px) {
      flex: 1 0 calc(33.33% - 1rem); // на экранах от 1024px три элемента в строке
    }

    @media screen and (min-width: 1280px) {
      flex: 1 0 calc(25% - 1rem); // на экранах от 1280px четыре элемента в строке
    }

    @media screen and (min-width: 2000px) {
      flex: 1 0 calc(20% - 1rem); // на экранах от 2000px пять элементов в строке
      min-width: 400px;
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 570px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 870px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1170px;
  }

  @media screen and (min-width: 2000px) {
    max-width: 2000px;
    row-gap: 3rem;
  }
}

.app__work-img {
  width: 100%;
  height: 230px;

  position: relative;

  img {
    width: 100%;
    height: 100%;

    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 2000px) {
      margin-top: 2rem;
    }
  }

  .app__work-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #fff;
    top: -25px;
  }
}

.app__works-more {
  all: unset;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 2rem 0;

  &:hover {
    background-color: var(--secondary-color);
    color: #fff;
  }
}
