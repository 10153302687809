@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --font-base: "DM Sans", sans-serif;
  --primary-color: #f0f7fa;
  --secondary-color: #3a797d;
  --black-color: #1a1a1a;
  --gray-color: #9c9c9c;
  --lightGray-color: #e0e0e0;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body,
html {
  width: 100%;
  height: 100%;
}
